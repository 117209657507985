const CONFIG = {
  fixedMetrics: ['Name', 'Day', 'Impressions', 'Measurable Impressions', 'Clicks', 'CTR'],
  groupBySelected: { id: 'date', label: 'Accumulated per Day' },
  optionalMetrics: [
    { id: 'vtr', label: 'VTR / LTR' },
    { id: 'views', label: 'Views / Listens' },
    { id: 'uu', label: 'UU' },
    { id: 'engagements', label: 'Engagements' },
    { id: 'revenue', label: 'Consumido' },
    { id: 'quartiles', label: 'Quartiles' },
    { id: 'viewability', label: 'Viewability' },
    { id: 'viewableImpressions', label: 'Viewable Impressions' },
    { id: 'trueViews', label: 'TrueViews' },
    { id: 'lqi', label: 'LQI' },
    { id: 'leads', label: 'Leads' },
    { id: 'visits', label: 'Visits' },
    { id: 'sessions', label: 'Sessions' },
  ],
  excludeOptionalMetricsToAll: ['leads', 'visits', 'sessions'],
  optionsAllowed: {
    standard: {
      campaign: [
        { id: 'date', label: 'Accumulated per Day' },
        { id: 'product', label: 'Product and Day' },
      ],
      product: [{ id: 'date', label: 'Accumulated per Day' }],
    },
    custom: {
      campaign: [
        { id: 'date', label: 'Accumulated per Day' },
        { id: 'product', label: 'Product and Day' },
        { id: 'creative', label: 'Product, Creative and Day' },
        { id: 'accumulated_creative', label: 'Product, Date and Accumulated Creative' },
      ],
      product: [
        { id: 'date', label: 'Accumulated per Day' },
        { id: 'creative', label: 'Product, Creative and Day' },
        { id: 'accumulated_creative', label: 'Product, Date and Accumulated Creative' },
      ],
    },
  },
  defaultTimezone: 'Europe/Madrid',
  timezoneOptions: ['Europe/Madrid', 'America/New_York'],
  errorMessageEmptyEmails: 'Emails not found!',
};

export default CONFIG;
