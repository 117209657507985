import { api } from '@/services/modules/ThirdParty/opportunity';
import { ANALYTICS_RESOURCE, REPORT_RESOURCE } from '@/services/keys';

export async function createReport(params = {}, config = { preventKey: 'create_report', responseType: 'blob' }) {
  const partialUrl = api.createUrl({
    [REPORT_RESOURCE]: undefined,
    [ANALYTICS_RESOURCE]: undefined,
  });

  const response = await api.get(partialUrl, params, config);

  return new Blob([response.data]);
}
