import { api } from '@/services/modules/ThirdParty/opportunity';
import { ANALYTICS_RESOURCE, REPORT_RESOURCE } from '@/services/keys';

export async function createReport(params = {}, isToValidate = false) {
  const partialUrl = api.createUrl({
    [REPORT_RESOURCE]: undefined,
    [ANALYTICS_RESOURCE]: undefined,
  });

  const config = {
    preventKey: 'create_report',
    responseType: isToValidate ? null : 'blob',
  };

  let response;
  try {
    response = await api.get(partialUrl, params, config);
  } catch (e) {
    // TODO: debido a que axios returna un error quemado cuando es de type blob y en este punto
    // TODO: no es posible reconocer el mensaje de error dado por el endpoint
    return e;
  }

  return new Blob([response.data]);
}
