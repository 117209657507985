<template>
  <div v-if="isShow" class="flex flex-col w-full">
    <label class="font-bold text-left text-sm mr-1 text-gray-700 mb-1">Current Reports</label>
    <div v-if="!isLoading" class="w-full">
      <div v-if="!emptyData">
        <table class="w-full">
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="`header-${index}`"
              class="font-bold text-left text-xs text-gray-700 w-1/5"
            >
              {{ header.name }}
            </th>
            <th class="font-bold text-center text-xs text-gray-700">Actions</th>
          </tr>
          <tr v-for="row in scheduledEmails" :key="row.id">
            <td v-for="(header, index) in headers" :key="`row-${index}`" class="text-left w-1/5">
              <span class="text-xs">{{ row[header.field] }}</span>
            </td>
            <td class="text-center w-1/5">
              <div class="flex justify-center w-full" @click="onDelete(row)">
                <sun-close-svg v-if="!isLoadingActions" title="Delete" color="red" class="cursor-pointer" />
                <sun-loading v-if="isLoadingActions" class="h-6 text-orange-500" />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="emptyData">
        <span class="font-bold text-xs text-gray-600">There are no scheduled reports yet!</span>
      </div>
    </div>
    <div v-if="isLoading" class="flex w-full justify-center items-center">
      <sun-loading class="h-12 text-orange-400" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SunCloseSvg from '@sunmedia/sun-ui/src/components/icons/SunCloseSvg.vue';
import { getScheduleReports, removeScheduleReport } from '@/services/modules/ThirdParty/mails';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import CONFIG from '@/components/organisms/modules/thirdParty/campaignManager/Mails/config';

export default {
  name: 'ScheduleReportsItemsList',
  components: { SunCloseSvg },
  props: {
    id: {
      type: String,
      required: true,
    },
    isShow: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      headers: CONFIG.headers,
      isLoading: false,
      isLoadingActions: false,
      scheduledEmails: [],
    };
  },
  computed: {
    emptyData() {
      return this.scheduledEmails.length === 0;
    },
  },
  mounted() {
    this.LoadScheduleReports();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async LoadScheduleReports() {
      this.isLoading = true;
      try {
        const params = new QueryParamsBuilder(1, 1000);
        params.addFilter('config', this.id);

        const { data } = await getScheduleReports(params);
        this.scheduledEmails = data;
      } catch (e) {
        await this.createToast(Toast.error('Failed get emails', e.message));
      }
      this.isLoading = false;
    },
    async onDelete(scheduledEmail) {
      this.isLoadingActions = true;
      try {
        await removeScheduleReport(scheduledEmail.id);
        this.removeScheduleReportTolist(scheduledEmail);

        this.createToast(Toast.success('The scheduled report deleted', 'The scheduled report has been deleted.'));
      } catch (e) {
        await this.createToast(Toast.error('Failed delete schedule report!', e.message));
      }
      this.isLoadingActions = false;
    },
    removeScheduleReportTolist(scheduledEmail) {
      this.scheduledEmails = this.scheduledEmails.filter(email => email.id !== scheduledEmail.id);
    },
  },
};
</script>
<style scoped></style>
