<template>
  <asterix-section :status="sectionStatus" :is-loading="isLoadingSection">
    <template v-if="showDatePicker" #header-right>
      <div>
        <sun-date-picker-v2
          id="campaign-manager-filter-date-range"
          ref="datePicker"
          :value="quickFiltersDate"
          no-header
          no-calendar-icon
          :range="true"
          show-date-list
          :date-list="dateList"
          @change="onQuickFiltersDate($event.value)"
          @select:date-list-option="selectDateOption"
        />
      </div>
    </template>
    <template #content>
      <div v-if="!hasSetting" class="flex flex-col w-full mb-8">
        <table-loader />
      </div>
      <div v-if="hasSetting" class="flex flex-col w-full mb-8">
        <ag-grid-vue
          :key="`campaign-manager_table_${tableKey}`"
          style="height: 500px"
          class="ag-theme-alpine"
          pagination
          :pagination-page-size="30"
          animate-rows
          suppress-agg-func-in-header
          :group-agg-filtering="true"
          :column-defs="tableColumnsDefinition"
          :column-types="columnTypes"
          :default-col-def="defaultColDef"
          :auto-group-column-def="autoGroupColumnDef"
          :row-class-rules="rowClassRules"
          :row-data="rowData"
          :tree-data="true"
          :group-default-expanded="groupDefaultExpanded"
          :cache-block-size="20"
          :scrollbar-width="10"
          :tooltip-show-delay="100"
          :get-data-path="getDataPath"
          @grid-ready="onGridReady"
          @column-moved="onMovingChanged"
        />
      </div>
      <create-report-modal
        v-if="showReportModal"
        :create-report-fn="createReport"
        :schedule-report-fn="scheduleReport"
        @cancel="closeReloadModal"
      />
    </template>
  </asterix-section>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapGetters } from 'vuex';
import { addMonths } from 'date-fns';
import AsterixSection from '@/components/templates/AsterixSection.vue';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { Toast } from '@/model/shared/Toast';
import CONFIG, { agGridColumns } from '@/views/private/modules/thirdParty/campaignManager/config';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/thirdParty/AnalyticsCellOrchestrator.vue';
import { stringToDate } from '@/filters/dateFilters';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { createReport, scheduleReport } from '@/services/modules/ThirdParty/reports';
import CreateReportModal from '@/components/organisms/modules/thirdParty/campaignManager/CreateReportModal.vue';
import { CLEAR_REPORT_MODAL, DATA_REPORT_MODAL, SHOW_REPORT_MODAL } from '@/store/modules/campaignManager/keys';
import { addDays } from '@/utils/dateTime/addDays';
import SunDatePickerV2 from '@/components/atoms/SunDatePickerV2/SunDatePickerV2.vue';
import { dateTimeFormat } from '@/utils/dateTime/date';
import AVAILABLE_SETTINGS, { CAMPAIGN_MANAGER } from '@/model/shared/user/availableSettings';
import { GET_USER_SETTINGS_REQUEST, UPDATE_USER_SETTINGS_REQUEST, USER_SETTINGS } from '@/store/modules/settings/keys';
import TableLoader from '@/components/atoms/Loaders/TableLoader.vue';

export default {
  name: 'CampaignManager',
  components: {
    TableLoader,
    CreateReportModal,
    AgGridVue,
    AsterixSection,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
    SunDatePickerV2,
  },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    showDatePicker: {
      type: Boolean,
      default: () => true,
    },
    funcGetOpportunities: {
      type: Function,
      default: () => null,
    },
    startDate: {
      type: Date,
      default: () => null,
    },
    endDate: {
      type: Date,
      default: () => null,
    },
  },
  mixins: [
    queryParamsMixin,
    filtersMixin({
      filters: [],
      filterQuick: {
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    // Config DatePicker
    dateList: CONFIG.dateListWithToday,
    defaultDate: CONFIG.defaultDate,
    // Config Table
    tableKey: 0,
    getDataPath: item => {
      return item.group;
    },
    groupDefaultExpanded: 1,
    sectionStatus: SECTION_STATUS.OK,
    isLoadingSection: false,
    gridApi: null,
    gridColumnsApi: null,
    columnTypes: CONFIG.columnTypes,
    defaultColDef: CONFIG.defaultColDef,
    exportParams: CONFIG.exportParams,
    rowClassRules: CONFIG.rowClassRules,
    rowData: [],
    autoGroupColumnDef: {
      headerName: 'NAME',
      headerTooltip: 'NAME',
      cellClass: params => {
        return params.data.type === 'Campaign' ? 'font-semibold' : '';
      },
      minWidth: 50,
      width: 400,
      maxWidth: 600,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      suppressMovable: true,
      type: 'textColumn',
      filter: 'agTextColumnFilter',
      filterParams: {
        defaultOption: 'contains',
        textMatcher: ({ value, filterText }) => {
          if (filterText == null) {
            return false;
          }

          return value.indexOf(filterText) >= 0;
        },
      },
      filterValueGetter: params => {
        return params.data.name;
      },
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: params => {
          return params.data.name;
        },
      },
      tooltipValueGetter: params => {
        return params.data.name;
      },
    },
    updateUserSettingsTimeout: null,
    settings: null,
    isLoadingSettings: false,
  }),
  computed: {
    ...mapGetters({
      showReportModal: SHOW_REPORT_MODAL,
      dataReportModal: DATA_REPORT_MODAL,
      userSettings: USER_SETTINGS,
    }),
    availableColumnsByProfile() {
      const columns = agGridColumns;

      if (this.settings?.hasTableByContext(AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT, CAMPAIGN_MANAGER)) {
        const orderTable = this.settings.getTableByContext(AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT, CAMPAIGN_MANAGER);
        columns.sort((a, b) => {
          const indexA = orderTable.indexOf(a.field);
          const indexB = orderTable.indexOf(b.field);

          return indexA - indexB;
        });
      }

      return columns;
    },
    tableColumnsDefinition() {
      return [...this.availableColumnsByProfile].map(
        ({
          headerName,
          field,
          headerClass,
          cellClassRules,
          visible,
          maxWidth,
          minWidth,
          width,
          filter,
          filterParams,
          type,
          pinned,
          sort,
          sortable,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
          suppressMovable,
          cellClass,
        }) => {
          return {
            headerName,
            field,
            hide: !visible,
            headerClass,
            cellClassRules,
            maxWidth,
            minWidth,
            width,
            pinned,
            filter,
            filterParams,
            type,
            sort,
            sortable,
            suppressSizeToFit,
            suppressAutoSize,
            enableValue: true,
            comparator,
            aggFunc: null,
            headerTooltip: headerName,
            suppressMovable,
            cellClass,
          };
        }
      );
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;
      let defaultEnd = addMonths(this.defaultDate.endDate, 1);
      defaultEnd = new Date(defaultEnd.getFullYear(), defaultEnd.getMonth(), 1);

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : addDays(defaultEnd, -1);

      return { startDate, endDate };
    },
    hasSetting() {
      return (
        !!this.userSettings &&
        !!this.userSettings?.hasTableByContext(AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT, CAMPAIGN_MANAGER)
      );
    },
  },
  watch: {
    userSettings: {
      deep: true,
      handler(newSettings) {
        this.setSettings(newSettings);
      },
    },
  },
  async mounted() {
    await this.setSettings(this.userSettings);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      clearReportModal: CLEAR_REPORT_MODAL,
      getUserSettings: GET_USER_SETTINGS_REQUEST,
      updateUserSettings: UPDATE_USER_SETTINGS_REQUEST,
    }),
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.filtersUpdateFiltersOnStoreAndURL(CONFIG.COMMON_ID_VIEW);
      await this.reloadTable();
    },
    selectDateOption(option) {
      if (option === CONFIG.CUSTOM_OPTION) {
        setTimeout(() => (this.$refs.datePicker.$refs.menu.isMenuActive = true), 0);
      }
    },
    setFilterQuick({ startDate, endDate }) {
      this.filterQuick.range_start = dateTimeFormat(startDate);
      this.filterQuick.range_end = dateTimeFormat(endDate);
    },
    reloadTable() {
      this.tableKey++;
      this.rowData = [];
    },
    async onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.gridColumnApi = columnApi;
      await this.loadOpportunities();
    },
    async loadOpportunities() {
      try {
        this.isLoadingSection = true;
        this.gridApi.showLoadingOverlay();
        const params = new QueryParamsBuilder(1, 10000);
        const { startDate, endDate } = this.quickFiltersDate;

        params.addFilter('product.start', this.startDate ?? startDate);
        params.addFilter('product.end', this.endDate ?? endDate);

        this.rowData = await this.funcGetOpportunities(params);
        this.isLoadingSection = false;
        this.gridApi.hideOverlay();
      } catch (error) {
        this.isLoadingSection = false;
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`Oops! We couldn't get the products`, error));
        }
      }
    },
    createReport,
    scheduleReport,
    closeReloadModal() {
      this.clearReportModal();
    },
    onMovingChanged() {
      const newOrder = this.gridColumnApi
        .getAllGridColumns()
        .filter(col => !col.colDef.hide)
        .map(col => col.colDef);

      this.settings.setTableByContext(AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT, CAMPAIGN_MANAGER, newOrder);
      if (this.updateUserSettingsTimeout) {
        clearTimeout(this.updateUserSettingsTimeout);
        this.updateUserSettingsTimeout = null;
      }

      this.updateUserSettingsTimeout = setTimeout(() => {
        this.updateUserSettings(this.settings);
      }, 2000);
    },
    async setSettings(settings) {
      if (!settings && !this.hasSetting && !this.isLoadingSettings) {
        await this.loadSettings();
      }
      if (settings && this.hasSetting && !this.settings && !this.isLoadingSettings) {
        this.settings = this.userSettings;
      }
    },
    async loadSettings() {
      this.isLoadingSettings = true;
      const settings = await this.getUserSettings();
      if (!settings.hasTableByContext(AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT, CAMPAIGN_MANAGER)) {
        const newSettings = settings.clone();
        await newSettings.setTableByContext(
          AVAILABLE_SETTINGS.ORDER_COLUMNS_DIRECT,
          CAMPAIGN_MANAGER,
          this.availableColumnsByProfile
        );
        await this.updateUserSettings(newSettings);
      }

      this.settings = settings;
      this.isLoadingSettings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

:deep(.ag-theme-alpine .ag-row.product-active) {
  @apply text-green-600 bg-green-100;
}
:deep(.ag-theme-alpine .ag-row.product-pending) {
  @apply text-blue-600 bg-blue-100;
}
:deep(.ag-theme-alpine .ag-row.product-prepared) {
  @apply text-yellow-600 bg-yellow-100;
}
:deep(.ag-theme-alpine .ag-row.product-pause) {
  @apply text-orange-600 bg-orange-100;
}
:deep(.ag-theme-alpine .ag-row.product-materials) {
  @apply text-red-600 bg-red-100;
}
:deep(.ag-theme-alpine .ag-row.product-finished) {
  @apply text-gray-600 bg-gray-300;
}

:deep(.main-headers) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.ag-sort-none-icon) {
  margin-left: 0px !important;
}

:deep(.border-r-transparent) {
  border-right-color: transparent;
}

:deep(.date.z-10) {
  @apply z-0;
}

:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}

:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}

:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}

:deep(.ag-theme-alpine .ag-floating-filter-button) {
  display: none;
}

:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}

:deep(.ag-theme-alpine .ag-row.deal-starting) {
  @apply text-green-600 bg-green-100;
}

:deep(.ag-theme-alpine .ag-row.deal-ending) {
  @apply text-red-500 bg-red-100;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']) {
  @apply pl-5;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  content: '';
  position: absolute;
  top: 14px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @apply bg-green-600;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']) {
  @apply pl-0;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before) {
  left: 38px;
}

:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  @apply bg-red-600;
}

:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-2:not(.ag-cell-expandable)) {
  padding-left: 50px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-1:not(.ag-cell-expandable)) {
  padding-left: 30px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent:not(.ag-cell-expandable)) {
  margin-left: 0;
}
</style>
