var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: {
      title: "Create Product Report",
      closable: "",
      "modal-class": "sm:max-w-6xl",
    },
    on: {
      submit: _vm.submitForm,
      keypress: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.submitForm.apply(null, arguments)
      },
      cancel: _vm.closeModal,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "overflow-visible pb-6 px-3" },
              [
                _c("form-row", [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mb-6",
                          attrs: { text: "Fixed metrics" },
                        },
                        _vm._l(_vm.fixedMetrics, function (label) {
                          return _c(
                            "span",
                            {
                              key: label,
                              class: `px-2 py-1 mr-1 border leading-tight align-middle inline-flex items-center bg-green-300 rounded-full`,
                            },
                            [
                              _c(
                                "span",
                                {
                                  class: `text-xs whitespace-no-wrap text-gray-900`,
                                },
                                [_vm._v(_vm._s(label))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "sun-label-group",
                        {
                          staticClass: "mb-6",
                          attrs: { text: "Optional metrics" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex w-full flex-col" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-bold text-left text-sm mr-1 sun-label text-gray-700 mb-1",
                                        },
                                        [_vm._v("Optional metrics")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-row flex-nowrap mb-1",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-left text-xs mr-1 sun-label",
                                            },
                                            [_vm._v("Select All")]
                                          ),
                                          _c("sun-checkbox", {
                                            class: `rounded-full`,
                                            attrs: {
                                              color: "green",
                                              value: _vm.selectedAllValue,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.selectOptions(
                                                  $event.value
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "w-full flex flex-wrap" },
                            _vm._l(
                              _vm.optionalMetrics,
                              function (option, index) {
                                return _c(
                                  "div",
                                  {
                                    key: `option__${index}`,
                                    staticClass:
                                      "flex items-center py-1 px-2 mr-1 mb-1 border inline-flex rounded-full cursor-pointer bg-gray-200",
                                    class: [
                                      _vm.metricsSelected.includes(option.id)
                                        ? "bg-green-300"
                                        : "",
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.onOptionClick(option.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { class: `text-xs text-gray-900` },
                                      [_vm._v(_vm._s(option.label))]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-row w-full mb-6" },
                        [
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "w-full mr-3",
                              attrs: { text: "Group by" },
                            },
                            [
                              _c("sun-select", {
                                attrs: {
                                  options: _vm.optionsSelect,
                                  "track-by": "id",
                                  label: "label",
                                  "close-on-select": "",
                                  "allow-empty": false,
                                },
                                model: {
                                  value: _vm.groupBySelected,
                                  callback: function ($$v) {
                                    _vm.groupBySelected = $$v
                                  },
                                  expression: "groupBySelected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "w-full",
                              attrs: { text: "Timezone" },
                            },
                            [
                              _c("sun-select", {
                                attrs: {
                                  id: "report-timezone",
                                  options: _vm.timezoneOptions,
                                  "add-hex-color": "orange",
                                  "color-tag": "gray",
                                  name: "reportTimezone",
                                  placeholder: "Select timezone",
                                  "close-on-select": "",
                                  required: "",
                                  "allow-empty": false,
                                },
                                model: {
                                  value: _vm.timezoneSelected,
                                  callback: function ($$v) {
                                    _vm.timezoneSelected = $$v
                                  },
                                  expression: "timezoneSelected",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "flex flex-col w-full" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "font-bold text-left text-sm mr-1 sun-label text-gray-700 mb-1",
                          },
                          [_vm._v("Send to email")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-1 flex-col items-start mb-4 sm:mb-0",
                          },
                          [
                            _c("sun-toggle", {
                              staticClass: "mb-2",
                              attrs: {
                                id: "toggle-report",
                                name: "toggle-report",
                              },
                              on: { change: _vm.selectSendToEmail },
                              model: {
                                value: _vm.shouldSenToEmails,
                                callback: function ($$v) {
                                  _vm.shouldSenToEmails = $$v
                                },
                                expression: "shouldSenToEmails",
                              },
                            }),
                            _vm.shouldSenToEmails
                              ? _c("multi-select-emails", {
                                  attrs: {
                                    "has-error": _vm.isEmptyEmails,
                                    "error-message":
                                      _vm.errorMessageEmptyEmails,
                                  },
                                  on: { change: _vm.changeEmails },
                                })
                              : _vm._e(),
                            _c("schedule-reports-items-list", {
                              attrs: {
                                id: _vm.dataReportModal.id,
                                "is-show": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                !_vm.shouldSenToEmails
                  ? _c("download-button", {
                      staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                      attrs: {
                        id: "download-report-submit",
                        loading: _vm.isLoading,
                        variant: "pill",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [_vm._v("Download ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1358919636
                      ),
                    })
                  : _vm._e(),
                _vm.shouldSenToEmails
                  ? _c("send-email-button", {
                      staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                      attrs: {
                        id: "send-email-report-submit",
                        loading: _vm.isLoading,
                        variant: "pill",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [_vm._v("Send")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1742764604
                      ),
                    })
                  : _vm._e(),
                _c(
                  "sun-button",
                  {
                    staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                    attrs: { variant: "pill", color: "white" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }